@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@layer base{
    body{
        @apply font-[Roboto]
    }
    li{
        @apply px-4;
        @apply cursor-pointer;
    }
    svg{
        width:30px;
        height:60px;
    }
    .content-div {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 250px;
    }
    .app{
        width:100%;
        height:100vh;
        --tw-bg-opacity: 1;
        background-color: rgb(10 25 47 / var(--tw-bg-opacity));
    }
    
    
    .content-div:hover {
        background-image: linear-gradient(
            to right,
            rgba(112, 157, 255, 0.8),
            hsla(242, 74%, 61%, 0.8)   
        )!important;
    }
}